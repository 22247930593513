// Landing Page Data
export const landingPageData = {
  coming_soon: "Designer Sale Alerts",
  heading: "Designer Sale Alerts",
  notify: {
    text: "Get a text message as soon as designer boutiques have new markdowns. Text 'frugal' to 855-900-7253 to sign up.",
    button: "Sign Up",
    placeholder: "(555) 555-5555",
  },
};

// Social Media List
export const socialMediaData = [
//   {
//     icon: "fab fa-twitter",
//     link: "http://www.twitter.com/",
//     name: "Twitter",
//     class: "social-icons-twitter",
//   },
//   {
//     icon: "fab fa-facebook",
//     link: "http://www.facebook.com/",
//     name: "Facebook",
//     class: "social-icons-facebook",
//   },
//   {
//     icon: "fab fa-instagram",
//     link: "http://www.instagram.com/",
//     name: "Instagram",
//     class: "social-icons-instagram",
//   },
];

// About Us Data
export const aboutData = {
  desc: "We obsessively monitor men's designer fashion websites for the best sales. Whether it's big sites like Mr. Porter or smaller boutiques like Notre Shop, we'll notify you of the best sales.",
  cards: [
      {
      icon: "far fa-solid fa-vest",
      title: "The Brands",
      desc: "We check boutiques and sites that carry a range of designer brands from Balenciaga and Celine to Stone Island and Visvim.",
    },
    {
      icon: "fa-solid fa-hand-holding-dollar",
      title: "No Charge",
      desc: "This service is free(!) so our text messages won't cost you anything other what you're already paying your carrier for text messaging.",
    },
    {
      icon: "far fa-solid fa-rectangle-xmark",
      title: "Easily Unsubscribe",
      desc: "Want to stop receiving texts? Unsubscribe any time by replying STOP.",
    },
  ],
  projects_title:"",
  projects: [

  ],
  get_in_touch: "Get in touch with us",
};

// Contact Us data
export const contactData = {
  desc: "Got questions? Get in touch.",
  support_note: "For Customer Support, Send us a note.",
  cards: [
    {
      icon: "fas fa-regular fa-envelope",
      title: "EMAIL",
      lines: ["info[at]designersalealerts.com"],
    },

  ],

  form: {
    nameLable: "What is Your Name:",
    namePlaceholder: "Enter Your Name",

    emailLable: "Your Email Address:",
    emailPlaceholder: "Enter Your Email",

    messageLable: "How can I Help you?",
    messagePlaceholder: "Enter Your Query",

    submitButton: "Send Message",
  },
  contact: "(+91) 11-2142-566",
};
